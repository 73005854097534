<template>
  <div class="tai-detail my-second bgcolor">
    <Head :title="$t('wallet.pv_list')" :show="true" />
    <div class="tai-detail-main">
        <div class="tai-top">
           <div class="tai-top-left" @click="showtime=true">
              <p> {{year}}{{$t('common.year')}}{{month | ten }}{{$t('common.month')}}</p>
              <van-icon name="arrow-down" class="icon"></van-icon>
           </div>
           <div class="tai-top-right" @click="show=true" >
             <p>{{withType}}</p>
             <van-icon name="arrow-down"  class="icon"></van-icon>
           </div>
        </div>
        <div class="tai-detail-list" v-if="list.length>0">
            <ul>
            <!-- <MyList ref="mylist" :listadd="listadd"> -->
              <li v-for="(l,i) in list" :key="i">
                  <div class="li-top li-item">
                      <p class="li-top-left van-multi-ellipsis--l2">{{l.type}}</p>
                      <p class="li-top-right " :class="l.is_send==2?'p-active':''">
                          <span>{{l.is_send==2?'+':'-'}}</span>  <img class="pv-img" :src="l.is_send==2?require('@/assets/images/pv-on.png'):require('@/assets/images/pv-off.png')" alt=""> <span>{{l.amount}}</span>
                      </p>
                  </div>
                  <div class="li-bottom li-item">
                      <p>{{l.time}}</p>
                      <!-- <p :class="l.status==803?'p-active':''">获取成功</p> -->
                      <p >{{l.remark}}</p>

                  </div>
              </li>
            <!-- </MyList> -->
            </ul>
        </div>
        <div v-else class="no-box">
                <img class="juan-no" :src="require('@/assets/images/xuqiu-no.png')" alt="">
                <p class="no-text">{{$t('wallet.no')}}{{$t('wallet.pv_list')}}</p>
          </div>
    </div>

      <!-- 币种选择  -->
     <van-popup class="login-popup taione-one" v-model="show" position="bottom" style="height:9rem;"  duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('wallet.choose')}}{{$t('wallet.Transaction_Type')}}</p>
            </div>
            <div class="btn-list">
                <van-button @click="changeType(-1,$t('common.all')+$t('wallet.Transaction_Type'),'-1','-1')"  class="btn-list-item " :class="current==-1?'active':''">{{$t('score.all')}}</van-button>
                <!-- <van-button @click="changeType(0,$t('home.qudao'),'87','88')"  class="btn-list-item"  :class="current==0?'active':''">{{$t('score.length')}}</van-button> -->
                
                <van-button @click="changeType(1,$t('home.bet'),'89','92')"   class="btn-list-item"  :class="current==1?'active':''">{{$t('score.auction')}}</van-button>
                <van-button @click="changeType(2,$t('home.qian'),'81','81')"  class="btn-list-item"  :class="current==2?'active':''">{{$t('mine.qiandao')}}</van-button>
                <van-button @click="changeType(3,$t('home.showdan'),'93','94')"  class="btn-list-item"  :class="current==3?'active':''">{{$t('score.sheet')}}</van-button>
                
                <van-button @click="changeType(4,$t('add.jiang_li'),'89','92')"   class="btn-list-item"  :class="current==4?'active':''">{{$t('add.jiang_li')}}</van-button>                
                <van-button @click="changeType(5,$t('home.recharge'),'82','82')"   class="btn-list-item"  :class="current==5?'active':''">{{$t('score.exchange')}}</van-button>
                <van-button @click="changeType(6,$t('add.Transfer'),'89','92')"   class="btn-list-item"  :class="current==6?'active':''">{{$t('add.Transfer')}}</van-button>
            </div>
            <van-button class="cancel-btn sure-btn" @click="show=false">{{$t('login.cancel')}}</van-button>
        </div>
    </van-popup>
    <!-- 选择年月 -->
    <van-popup v-model="showtime" class="time-popup" position="bottom"   duration="0.2" >
        <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            class="time-picker"
            @cancel="showtime=false"
            @confirm="onConfirm"
            :formatter="formatter"
        />
    </van-popup>
  </div>
</template>
<script>
export default {
  name:'wallet-pvdetail',
  data(){
    return {
      show:false,
      showtime:false,
      currentDate: new Date(),
    page:1,
    pageSize:50,
    total:0,
    max:'-1',
    min:'-1',
      list:[],
      year:"",
      month:"",
      oldlist:[],
      current:-1,
       withType:this.$t('common.all')+this.$t('wallet.Transaction_Type')
    }
  },
  mounted(){
        //  PV交易明细
      this.$ajax.walletlog({
          current_id:1033,
          page:this.page,
          pageSize:this.pageSize
      })
      .then(res=>{
          if(res.code==200){
               this.list =res.data.data;
               this.oldlist = res.data.data;
               this.total = res.data.total
          }
      }) 

      this.year = new Date().getFullYear();
      this.month = new Date().getMonth() + 1;
  },
  methods:{
        getdatabytype(type){
            this.$ajax.walletlog({
            current_id:1033,
            type
        })
        .then(res=>{
            if(res.code==200){
                this.list =res.data.data;
                this.oldlist = res.data.data;
                this.total = res.data.total
            }
        }) 
      },
      listadd(cb1,cb2){
            if(this.current=='-1'){
                if(this.page<Math.ceil(this.total/this.pageSize)){
                    this.page++;
                    this.$ajax.walletlog({
                        current_id:1033,
                        page:this.page,
                        pageSize:this.pageSize
                    })
                    .then(res=>{
                        if(res.code==200){
                            // this.list = this.list.concat(res.data.list);
                            this.oldlist = this.oldlist.concat(res.data.data);
                            this.total = res.data.total;
                            this.changeList(this.max,this.min)
                        }
                        cb1();
                    })
                }else{
                    cb2()
                }
            }
        },
        changeList(max,min){
            console.log(max,min)
           if(max=='-1'&&min=='-1'){
               this.list = [...this.oldlist]
           }else if(max=="0"&&min=="0"){
               this.list = [];
           }else{
               this.list = this.oldlist.filter(item=>{
                   return item.rechargeType >= min * 1 && item.rechargeType <= max * 1;
               })
           }
      },
       changeType(current,type,min,max){
           if(this.current!==current){
                this.current = current;
                this.withType = type
                this.max = max
                this.min = min 
                    //   过滤代码
                this.show = false;
                this.getdatabytype(current)
           }

        //    if(max=='-1'&&min=='-1'){
        //        this.list = [...this.oldlist]
        //    }else if(max=="0"&&min=="0"){
        //        this.list = [];
        //    }else{
        //        this.list = this.oldlist.filter(item=>{
        //            return item.rechargeType >= min * 1 && item.rechargeType <= max * 1;
        //        })
        //    }
       },
      onConfirm(value){
          this.showtime = false;
          
          this.year = new Date(value).getFullYear();
          this.month = new Date(value).getMonth() + 1;
          this.list = this.oldlist.filter(item=>{
              let year = new Date(item.time).getFullYear()
              let month = new Date(item.time).getMonth() + 1
              return year==this.year&&month==this.month;
          })    
      },
      formatter(type, val) {
        if (type === 'year') {
            return `${val}`;
        } else if (type === 'month') {
            return `${val}`;
        }
      return val;
    }
  }
}
</script>